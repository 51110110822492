exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-art-page-index-tsx": () => import("./../../../src/templates/ArtPage/index.tsx" /* webpackChunkName: "component---src-templates-art-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-experiences-page-index-tsx": () => import("./../../../src/templates/ExperiencesPage/index.tsx" /* webpackChunkName: "component---src-templates-experiences-page-index-tsx" */),
  "component---src-templates-facilities-page-index-tsx": () => import("./../../../src/templates/FacilitiesPage/index.tsx" /* webpackChunkName: "component---src-templates-facilities-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-info-page-index-tsx": () => import("./../../../src/templates/InfoPage/index.tsx" /* webpackChunkName: "component---src-templates-info-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-menu-page-index-tsx": () => import("./../../../src/templates/MenuPage/index.tsx" /* webpackChunkName: "component---src-templates-menu-page-index-tsx" */),
  "component---src-templates-offer-form-page-index-tsx": () => import("./../../../src/templates/OfferFormPage/index.tsx" /* webpackChunkName: "component---src-templates-offer-form-page-index-tsx" */),
  "component---src-templates-offer-page-index-tsx": () => import("./../../../src/templates/OfferPage/index.tsx" /* webpackChunkName: "component---src-templates-offer-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-room-page-index-tsx": () => import("./../../../src/templates/RoomPage/index.tsx" /* webpackChunkName: "component---src-templates-room-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-spa-page-index-tsx": () => import("./../../../src/templates/SpaPage/index.tsx" /* webpackChunkName: "component---src-templates-spa-page-index-tsx" */)
}

