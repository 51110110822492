import styled from '@emotion/styled'
import { useLenis } from '@studio-freight/react-lenis'
import { Symbol } from 'app/components/Icons'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
  location: any
}

export const Transition = memo(function Transition({
  children,
  location,
}: Props) {
  const lenisInstance = useLenis()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    history.scrollRestoration = 'manual'
  }, [])

  const variants = {
    visible: {
      opacity: 1,
      zIndex: 10000,
      transition: {
        duration: 0.4,
        ease: [0.22, 1, 0.36, 1],
      },
    },
    hidden: {
      opacity: 0,
      zIndex: -1,
      transition: {
        delay: 1,
        duration: 0.6,
        ease: [0.22, 1, 0.36, 1],
        zIndex: {
          delay: 1.2,
        },
      },
    },
  }

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <Aside
          className={loaded ? 'loaded' : undefined}
          initial="visible"
          animate="hidden"
          exit="visible"
          onAnimationStart={() => {
            lenisInstance?.stop()

            setLoaded(true)
          }}
          onAnimationComplete={() => {
            lenisInstance?.start()

            setLoaded(false)
          }}
          variants={variants}
        >
          <Wrap>
            <Symbol />
            <Symbol />
          </Wrap>
        </Aside>

        {children}
      </Main>
    </AnimatePresence>
  )
})

const Main = styled(motion.main)``

const Wrap = styled.div`
  svg {
    width: 21.111vw;
    max-width: 19.375rem;
    height: auto;
    fill: ${rgba(theme.colors.variants.primaryLight, 0.1)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    &:last-of-type {
      clip-path: inset(100% 0% 0% 0%);
      fill: ${({ theme }) => theme.colors.variants.primaryLight};
    }

    path {
      fill: unset;
    }
  }

  @media (max-width: 1199px) {
    svg {
      width: 40vw;
    }
  }
`

const Aside = styled(motion.aside)`
  width: 100%;
  height: 100svh;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  position: fixed;
  top: 0;
  left: 0;
  &.loaded {
    ${Wrap} {
      svg {
        &:last-of-type {
          clip-path: inset(0);
          transition: 0.8s 0.2s ease-in-out;
        }
      }
    }
  }
`
